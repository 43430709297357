<template>
	<!--测试-测试用例 -->
  <div class="container" style="padding: 20px;">
    <GModule>
		<div class="condition-box">
			<div class="state-box">
				<p>状态</p>
				<a-dropdown>
				    <template #overlay>
				        <a-menu selectable @click="handleMenuClick">
							<a-menu-item v-for="(sitem,sindex) in stateArr" :key="sindex">{{sitem}}</a-menu-item>
				        </a-menu>
				    </template>
				    <a-button>
						{{stateIndex == 'all' ? '全部' : stateArr[stateIndex]}}
				        <DownOutlined />
				    </a-button>
				</a-dropdown>
			</div>
			<div class="state-box">
				<p>用例类型</p>
				<a-dropdown>
				    <template #overlay>
				        <a-menu selectable @click="handleManClick">
							<a-menu-item v-for="(mitem,mindex) in manArr" :key="mindex">{{mitem}}</a-menu-item>
				        </a-menu>
				    </template>
				    <a-button>
						{{manIndex == 'all' ? '全部' : manArr[manIndex]}}
				        <DownOutlined />
				    </a-button>
				</a-dropdown>
			</div>
			<a-button class="btn clo3" @click="search()">查询</a-button>
			<div class="flx1"></div>

			<!-- <a-button class="btn clo1" v-if="authority.add" @click="creatCase()">+建用例</a-button> -->
			<a-button class="btn clo2" v-if="authority.export" @click="exportExcl">导出</a-button>
			<a-button class="btn clo3" v-if="authority.submit" @click="confirm(null,'submit')">批量提交</a-button>
			<a-button class="btn clo1" v-if="authority.review" @click="confirm(null,'review')">批量通过</a-button>
			<a-button class="btn clo1" v-if="authority.complete" @click="confirm(null,'complete')">批量测试通过</a-button>
			<a-button class="btn clo1" v-if="authority.dev_complete" @click="confirm(null,'dev_complete')">批量完成测试</a-button>

			<a-modal v-if="modals" v-model:visible="omodals" :title="modals.title" @ok="confirm(modals.ids,modals.fun)" @cancel="modals=null">
				<div v-text="modals.name"></div>
			</a-modal>


		</div>
		<a-table 
			class="ttable" 
			:columns="columnshd" 
			:data-source="data" 
			:scroll="{ x: 500 }" 
			bordered
			:pagination="false"
			:row-selection="{ columnWidth:25,selectedRowKeys: rowSelection, onChange: onSelectChange }">
		    <template #bodyCell="{ column ,record,index}">
						<!-- <template v-if="column.title === '状态'">
						  	<p :style="(record.review_status_text||record.dev_status_text||record.status_text) == 'fail' ? 'color:red;' : ''">{{record.review_status_text||record.dev_status_text||record.status_text}}</p>
						</template> -->
						<template v-if="column.title === '用例ID'">
							<div class="dian">
								<p class="red_dian" v-if="authority.re_index&&record.review_status === 'fail'"></p>
								{{record.id}}
							</div>
						</template>
						<template v-if="column.title === '用例摘要'">
							<div class="ellipsis-rows">
								<a-tooltip placement="bottom" color="#ffffff" overlayClassName="overlayClassName">
							        <template #title>
							          <span style="color: #666666;">{{record.describe}}</span>
							        </template>
							        <p style="margin: 0;">{{record.describe}}</p>
							    </a-tooltip>
							</div>
						</template>
						<template v-if="column.title === '前置条件'">
							<div class="ellipsis-rows">
								<a-tooltip placement="bottom" color="#ffffff" overlayClassName="overlayClassName">
							        <template #title>
							          <span style="color: #666666;">{{record.preconditions}}</span>
							        </template>
							        <p style="margin: 0;">{{record.preconditions}}</p>
							    </a-tooltip>
							</div>
						</template>


						<template v-if="column.title === '操作步骤'">
							<div class="ellipsis-rows">
								<a-tooltip placement="bottom" color="#ffffff" overlayClassName="overlayClassName">
							        <template #title>
							          <span style="color: #666666;">{{record.content}}</span>
							        </template>
							        <p style="margin: 0;">{{record.content}}</p>
							    </a-tooltip>
							</div>
						</template>

						<template v-if="column.title === '预期结果'">
							<div class="ellipsis-rows">
								<a-tooltip placement="bottom" color="#ffffff" overlayClassName="overlayClassName">
							        <template #title>
							          <span style="color: #666666;">{{record.results}}</span>
							        </template>
							        <p style="margin: 0;">{{record.results}}</p>
							    </a-tooltip>
							</div>
						</template>


						<template v-if="column.title === '状态'">
							<template v-if="column.dataIndex === 'status_text'">
								<p :style="record.status === 'fail' ? 'color:red;' : ''">
									{{record.status_text}}
								</p>
							</template>
							<template v-if="column.dataIndex === 'review_status_text'">
								<p :style="record.review_status === 'fail' ? 'color:red;' : ''">
									{{record.review_status_text}}
								</p>
							</template>
							<template v-if="column.dataIndex === 'dev_status_text'">
								<p :style="record.dev_status === 'fail' ? 'color:red;' : ''">
									{{record.dev_status_text}}
								</p>
							</template>
						</template>
						<template v-if="column.title === '开发自测状态'">
							<p :style="record.dev_status === 'fail' ? 'color:red;' : ''">
								{{record.dev_status_text}}
							</p>
						</template>
						<template v-if="column.key === 'operation'">
								<div class="caozuo">

									<!-- 测试 -->
									<!-- <a-popconfirm
										v-if="authority.submit"
										title="确定提交所选需求吗?"
										ok-text="确定"
										cancel-text="取消"
										:disabled="record.status!= 'wait'&&record.status!='fail'"
										@confirm="confirm(record.id,'submit')">
										<p :class="record.status!= 'wait'&&record.status!='fail'?'clo9':''">提交</p>
									</a-popconfirm> -->
									<p 
										v-if="authority.submit"
										:class="record.status!= 'wait'&&record.status!='fail'?'clo9':''" 
										@click="record.status!= 'wait'&&record.status!='fail' ? false : confirm(record.id,'submit')">提交</p>

									<p v-if="authority.edit" @click="updateClick(record.id)">修改</p>

									<a-popconfirm
										v-if="authority.del"
										title="确认是否删除?"
										ok-text="确定"
										cancel-text="取消"
										@confirm="confirm(record.id,'del')">
										<p>删除</p>
									</a-popconfirm>

									<p 
									@click="record.status!='wait_test'?false:goToBug(record.id)" 
									v-if="authority.bug" 
									:class="record.status!='wait_test'?'clo9':''">
									转BUG
									</p>

									<!-- <a-popconfirm
										v-if="authority.complete"
										title="确定测试通过?"
										ok-text="确定"
										cancel-text="取消"
										:disabled="record.status!='bug'&&record.status!='wait_test'"
										@confirm="confirm(record.id,'complete')">
										<p :class="record.status!='bug'&&record.status!='wait_test'?'clo9':''">测试通过</p>
									</a-popconfirm> -->
									<p 
										v-if="authority.complete"
										:class="record.status!='bug'&&record.status!='wait_test'?'clo9':''"
										@click="record.status!='bug'&&record.status!='wait_test' ? false : confirm(record.id,'complete')">测试通过</p>
									<!-- 测试 -->

									<!-- 开发 -->
									<!-- <a-popconfirm
										v-if="authority.dev_complete"
										title="确定完成测试?"
										ok-text="确定"
										cancel-text="取消"
										:disabled="record.dev_status == 'pass' || record.status=='pass'||record.status=='reviewing'||record.status=='wait'"
										@confirm="confirm(record.id,'dev_complete')">
										<p :class="record.dev_status == 'pass' || record.status=='pass'||record.status=='reviewing'||record.status=='wait'?'clo9':''">完成测试</p>
									</a-popconfirm> -->
									<p 
										v-if="authority.dev_complete"
										:class="record.dev_status == 'pass' || record.status=='pass'||record.status=='reviewing'||record.status=='wait'?'clo9':''"
										@click="record.dev_status == 'pass' || record.status=='pass'||record.status=='reviewing'||record.status=='wait' ? false : confirm(record.id,'dev_complete')">完成测试</p>
									<!-- 开发 -->

									<!-- 审阅 -->
										<!-- <a-popconfirm
											v-if="authority.review"
											title="确定通过测试用例?"
											ok-text="确定"
											cancel-text="取消"
											:disabled="record.review_status =='pass'"
											@confirm="confirm(record.id,'review')">
											<p :class="record.review_status == 'pass'?'clo9':''">通过</p>
										</a-popconfirm> -->
										<p 
											v-if="authority.review" 
											:class="record.review_status == 'pass'?'clo9':''"
											@click="record.review_status =='pass' ? false : confirm(record.id,'review')">通过</p>

										<p v-if="authority.fail" :class="record.review_status!= 'wait'&&record.review_status!='part'?'clo9':''" @click="setFailModal(record)">驳回</p>
										<a-modal v-if="record.id==failModal" v-model:visible="failModal" title="驳回理由" @ok="failSubmit(failModal)">
											<a-form-item label="驳回理由">
												<a-input v-model:value="failText" placeholder="请输入驳回理由" />
											</a-form-item>
										</a-modal>


									<!-- 审阅 -->


									<p @click="detail(record.id,record.status,record.review_statu,record.dev_status)">详情</p>
								</div>
						</template>
		    </template>
		</a-table>
		<div class="documentFt" style="margin-top: 10px;">
		  <a-pagination size="small" :pageSize="limit" :total="total" :current="page" @change="pageChange"/>
		</div>
    </GModule>
  </div>
</template>
<script>
export default {
  name: 'TheTestCase',
  data(){
    return {
			stateArr:{},// 状态列表
			stateIndex:'all',
			manArr:{} ,// 用例类型列表
			manIndex:'all',
    	failModal:null,
    	failText:'',
			data: [],
			limit:10,
			page:1,
			total:0 ,// 总条数
			rowSelection:[],
    	modals: null,
		exportModals:null,
    }
  },
  computed: {
    selectedMemu(){
    	return this.$store.state.selectedMemu
    },
    authority(){
      var data = {};
      this.$store.state.params.forEach(item=>{
      	let name = item.name.split('/');
      	if(name[1]=='cases'){
	        data[name[2]] = true;
      	// }else if(name[1]=='demand_date'){
	      //   data[name[2]+'Date'] = true;
      	// }else if(name[1]=='cases'){
	      //   data[name[2]+'Cases'] = true;
      	// }else if(name[1]=='task'){
	      //   data['createTask'] = true;
      	}
      })
      return data
    },
    columnshd(){
    	var arr = [
					{
					  title: '用例ID',
					  width: 80,
					  dataIndex: 'id',
					  fixed: 'left'
					}, {
					  title: '用例类型',
					  width: 130,
					  dataIndex: 'case_type_text',
					  ellipsis: true,
					}, {
					  title: '用例摘要',
					  width: 230,
					  dataIndex: 'describe',
					  // ellipsis: true,
					}, {
					  title: '前置条件',
					  width: 220,
					  dataIndex: 'preconditions',
					  // ellipsis: true,
					}, {
					  title: '操作步骤',
					  width: 220,
					  dataIndex: 'content'
					}, {
					  title: '预期结果',
					  width: 220,
					  dataIndex: 'results',
					  // ellipsis: true,
					}
				];
				if(this.authority.edit||this.authority.submit||this.authority.del){
					arr = arr.concat([{
							  title: '状态',
							  width: 70,
							  dataIndex: 'status_text'
							}, {
							  title: '创建时间',
							  width: 100,
							  dataIndex: 'createtime_text'
							},{
							  title: '操作',
							  key: 'operation',
							  fixed: 'right',
							  width: 120,
						}])
				}else if(this.authority.review||this.authority.fail){
					arr = arr.concat([{
							  title: '提审时间',
							  width: 110,
							  dataIndex: 'review_time_text'
							},{
							  title: '状态',
							  width: 70,
							  dataIndex: 'review_status_text'
							}, {
							  title: '操作',
							  key: 'operation',
							  fixed: 'right',
							  width: 100,
						}])
				}else if(this.authority.pr_index){
					// 项目经理
					arr = arr.concat([{
							  title: '提审时间',
							  width: 110,
							  dataIndex: 'review_time_text'
							},{
							  title: '状态',
							  width: 70,
							  dataIndex: 'status_text'
							},{
							  title: '开发自测状态',
							  width: 90,
							  dataIndex: 'dev_status_text'
							}, {
							  title: '操作',
							  key: 'operation',
							  fixed: 'right',
							  width: 100,
						}])
				}else{
					arr = arr.concat([{
							  title: '状态',
							  width: 70,
							  dataIndex: 'dev_status_text'
							}, {
							  title: '操作',
							  key: 'operation',
							  fixed: 'right',
							  width: 100,
						}])
				}
			return arr
    },
    listUrl(){
    	var data= this.$store.state.params.find(ite=>ite.title.indexOf('首页')>-1)||{};
    	return data.name? data.name.replace('api','') : ''
    },
    mId(){
    	var id = 0;
    	if(this.selectedMemu&&this.selectedMemu.length)id=this.selectedMemu[this.selectedMemu.length - 1].ids;
    	return id
    },
		omodals(){
			return !!this.modals
		},
		proId(){
			return this.$store.state.proId  
		},
  },
  watch:{
    mId(){
			this.stateIndex = 'all'
			this.manIndex = 'all'
			this.page = 1
			this.getList()
    },
    listUrl(){
    	if(this.listUrl)this.getList()
    },
	proId(val,oldvalue){
		if(val){
			this.page = 1 
			this.getList()
		}
	}
  },
  async created() {
  	// 获取下拉数据
  	let res = await this.$utils.api.get({
  		url:'/cases/setting',
  		result:1
  	})
  	if(res.code == 1){
  		let a = {all:"全部"}
  		if(this.authority.re_index){
	  		this.stateArr = {...a,...res.data.reviewStatusList}
  		}else if(this.authority.de_index){
	  		this.stateArr = {...a,...res.data.devStatusList}
  		}else{
	  		this.stateArr = {...a,...res.data.statusList}
  		}
  		this.manArr = {...a,...res.data.caseTypeList}
  	}
		// 初始化列表
		this.getList()
  },
  methods:{
  	setFailModal(record){
  		if(record.review_status=='wait'||record.review_status=='part'){
	  		this.failModal = record.id;
  		}
  	},
  	async failSubmit(){
  		if(!this.failText){
  			return this.$utils.msgErr('请输入驳回理由！')
  		}
			let res = await this.$utils.api.post({
				url:"/cases/fail",
				data:{
					ids: this.failModal,
					msg: this.failText
				},
				result:1
			})
			if(res.code==1){
				this.$utils.msgSuc('已驳回')
				this.failModal = null;
				this.failText = '';
				this.getList()
			}
  	},
	  handleMenuClick(e){
		  this.stateIndex = e.key
	  },
	  handleManClick(e){
		  this.manIndex = e.key
	  },
	  pageChange(page,pageSize){
	  	// 切换页码时查询列表
	  	this.page = page
	  	this.getList()
	  },
	  search(){
	  	// 根据条件查询列表
	  	this.page = 1 //每次点击查询按钮都要重置页码为1
	  	this.getList()
	  },
	  async getList(){
			// 请求测试用例列表
			let info = {
			sort:'status asc,id desc',
				limit:this.limit,
				page:this.page,
				op:{},
				filter:{}
			}
			if(this.mId){
			// 模块id
				info.op['modular_id'] = 'IN'
				info.filter['modular_id'] = this.mId
			}
			if(this.stateIndex != 'all'){
				// 状态
				if(this.authority.re_index){
					info.op['review_status'] = 'IN'
					info.filter['review_status'] = this.stateIndex
				}else{
					info.op['status'] = 'IN'
					info.filter['status'] = this.stateIndex
				}
			}
	  	if(this.manIndex != 'all'){
			// 用例类型
	  		info.op['case_type'] = 'IN'
	  		info.filter['case_type'] = this.manIndex
	  	}
		
			if(!this.listUrl || this.listUrl == '')return;
	  	let res = await this.$utils.api.post({
	  		url:this.listUrl,
	  		data:info,
	  		result:1
	  	})
	  	if(res.code == 1){
	  		this.data = res.data.rows.map(item=>{
					item.content = '';
					if(item.steps){
						item.steps.forEach((ite,idx)=>{
							if(item.content)item.content+='　';
							item.content+=ite.index+'. '+ite.content;
						})
					}
					item.key = item.id
					return item
				})
	  		this.total = res.data.total
	  	}
	  },
	  // creatCase(){
			// // 跳转建用例页面
			// this.$router.push({name:'TheTestCaseAdd'})
	  // },

	  updateClick(id){
		  // 修改
		  this.$router.push({name:'TheTestCaseAdd',query:{id:id}})
	  },





		async confirm(ids,name){
				var type = {
					submit:'提交',
					complete:'测试通过',
					dev_complete:'完成测试',
					del:'删除',
					review:'通过审阅',
				};
				if(!ids){
					let arr = [];
					for(var i in this.rowSelection){
						arr.push(this.rowSelection[i])
					}
					ids=arr+'';
					if(!ids){
						this.$utils.msgErr('没有选中测试用例！')
					}else{
						console.log(ids+'?????????????????????????????????????????????????')
						this.modals = {
							title:'确认'+type[name]+'？',
							name:'是否确认'+type[name]+'ID为 '+ids+'的测试用例？'+(type=='submit'?'已提交过的不会重复提交':''),
							fun:name,
							ids:ids
						};
					}
					return
				}

				let res = await this.$utils.api.post({
						url:'/cases/'+name,
						data:{
							ids:ids
						},
						result:1
					})
				this.modals = null;
				if(res.code == 1){
					this.$utils.msgSuc(type[name]+'成功')
				  this.getList()
				}
	  },

	  detail(id,status,review_status,dev_status){
		  // 跳转详情页
		  this.$router.push({
			  name:'TheTestCaseDetail',
			  query:{
				  id:id,
				  status:this.authority.review ? review_status : this.authority.de_index ? dev_status : status,
				  bug:this.authority.bug ? 1 : 0,
				  del:this.authority.del ? 1 : 0,
				  edit:this.authority.edit ? 1 : 0,
				  submit:this.authority.submit ? 1 : 0,
				  review:this.authority.review ? 1 : 0,
				  fail:this.authority.fail ? 1 : 0,
				  dev_complete:this.authority.de_index&&this.authority.dev_complete ? 1 : 0,
			  },
		  })
	  },
	  async goToBug(id){
		  // 转bug
		  let res = await this.$utils.api.post({
		  	url:'/cases/getRow',
		  	data:{
		  		ids:id
		  	},
		  	result:1
		  })
		  if(res.code == 1){
		  	this.$router.push({name:'SubmitBug',query:{id:res.data.demand.id,changeId:id}})
		  }
	  },
	  onSelectChange(arr){
	  	this.rowSelection = arr;
	  },
		exportExcl(){
			this.$utils.api.downLoad({
				url:'/cases/export',
				data:{
					modular_id:this.mId||undefined,
					action_name:this.listUrl.split('/')[2]
				},
				name:'测试用例文件'
			})
		},

	  // async confirm(id,index){
	  // 	// 确认提交
			// let res = await this.$utils.api.post({
			// 	url:'/cases/submit',
			// 	data:{
			// 		ids:id
			// 	},
			// 	result:1
			// })
			// if(res.code == 1){
			// 	this.$utils.msgSuc('提交成功')
			// 	this.data[index].status = 'reviewing'
			// 	this.data[index].status_text = '审阅中'
			// }
	  // },
	  // async del(id,index){
		 //  // 删除
		 //  let res = await this.$utils.api.post({
			//   url:'/cases/del',
			//   data:{
			// 	  ids:id
			//   },
			//   result:1
		 //  })
		 //  if(res.code == 1){
			//   this.$utils.msgSuc('删除成功')
			//   this.data.splice(index,1)
		 //  }
	  // },
	  // async testOk(id,index){
		 //  // 测试通过.
		 //  let res = await this.$utils.api.post({
			//   url:'/cases/complete',
			//   data:{
			// 	  ids:id
			//   },
			//   result:1
		 //  })
		 //  if(res.code == 1){
			//   this.$utils.msgSuc('测试已通过')
			//   this.data[index].status = 'pass'
			//   this.data[index].status_text = '测试通过'
		 //  }
	  // },
	  // async testSubmit(ids){ // 批量提交
	  // 	if(!ids){
	  // 		let arr = [];
	  // 		for(var i in this.rowSelection){
	  // 			arr.push(this.rowSelection[i])
	  // 		}
	  // 		ids=arr+'';
	  // 		console.log("哈哈哈哈",ids)
	  // 		if(!ids){
	  // 			this.$utils.msgErr('没有选中测试用例！')
	  // 		}else{
	  // 			this.dels = ids;
	  // 		}
	  // 		return
	  // 	}
		
			// console.log("啦啦啦啦啦",ids)
			// // let res = await this.$utils.api.post({
			// // 		url:'/demand/del',
			// // 		data:{
			// // 			ids:ids
			// // 		},
			// // 		result:1
			// // 	})
			// // this.dels = '';
			// // 	if(res.code == 1){
			// // 		this.$utils.msgSuc('删除成功！');
			// // 	  this.getList()
			// // 	}
	  // }
  }

}
</script>

<style scoped lang="scss">
.product{
	display: flex;
	.add{
		width: 40px;
		height: 40px;
		margin-left: 20px;
		.ant-btn-icon-only{
			width: 40px !important;
			height: 40px !important;
			padding:0 !important;
		}
	}
}
.condition-box{
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	margin: 5px 0 10px 0;
	.flx1{
		flex:1;
	}
	.state-box{
		display: flex;
		justify-content: flex-start;
		align-items: center;
		margin-bottom: 5px;
		p{
			margin: 0 10px 0 20px;
		}
	}
	.btn{
		margin-left: 20px;
		margin-bottom: 5px;
	}
}
.ttable{
	margin-top:0;	
	.txt{
		display: flex;
		align-items: center;
		.red{
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background-color: red;
			margin-right: 5px;
		}
	}
}
.documentFt{
    margin-top:20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.caozuo{
	display: flex;
	flex-wrap: wrap;
	p{
		margin: 0;
		font-size: 12px;
		color: #407cff;
		margin-right: 10px;
	}
	.clo9{
		color:#999;
	}
}
.dian{
	display: flex;
	justify-content: center;
	align-items: center;
	.red_dian{
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background-color: red;
		padding: 0;
		margin: 0;
		margin-right: 5px;
	}
}


</style>